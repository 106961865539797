"use client"

import React, { useEffect } from "react"
import checkAuthStatus from "@/lib/checkAuthStatus"

const AuthStatusMonitor = React.memo(() => {
  useEffect(() => {
    const handleStorageChange = async (event: { key: string }) => {
      if (event.key === "login_status") {
        await checkAuthStatus()
      }
    }

    window.addEventListener("storage", handleStorageChange)

    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  return null
})

export default AuthStatusMonitor
