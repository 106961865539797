"use client"

import Cookies from "js-cookie"
import API, { AUTH_COOKIE_PATH, CSRF_COOKIE_PATH } from "@/lib/api"

async function checkAuthStatus() {
  let authStatus: number
  try {
    const authToken = Cookies.get(AUTH_COOKIE_PATH)
    const csrfToken = Cookies.get(CSRF_COOKIE_PATH)
    if (authToken) {
      API.setToken(authToken)
    }
    if (csrfToken) {
      API.setXCSRFToken(csrfToken)
    }
    const { data } = await API.get("/api/check_auth_status/")
    if (data) {
      authStatus = 1
    } else {
      authStatus = 0
    }
  } catch (e) {
    authStatus = 0
  }
  if (authStatus) {
    window.location.href = "/dashboard"
  } else {
    window.location.href = "/login"
  }
}
export default checkAuthStatus
