import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import type { IBookmark } from "@/types/Bookmark"

export interface BookmarksState {
  selectedBrandOrArtist: { slug: string; id: number } | undefined
  bookmarks: IBookmark[] | undefined
}

const initialState: BookmarksState = {
  selectedBrandOrArtist: undefined,
  bookmarks: [] as IBookmark[] | undefined,
}
const bookmarksSlice = createSlice({
  name: "bookmarks",
  initialState,
  reducers: {
    setSelectedArtistOrBrand(
      state,
      action: PayloadAction<{ slug: string; id: number } | undefined>
    ) {
      // eslint-disable-next-line no-param-reassign
      state.selectedBrandOrArtist = action.payload
    },
    setBookmarks(state, action: PayloadAction<IBookmark[] | undefined>) {
      // eslint-disable-next-line no-param-reassign
      state.bookmarks = action.payload
    },
  },
})

export const bookmarkActions = bookmarksSlice.actions

export default bookmarksSlice
