import { InboundProject, PROJECT_STATUS } from "@/types/Project"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Request } from "@/types/Request"
import { PROJECT_ARCHIVED_STATUSES } from "@/lib/constants"

export type InBoundProjectsResponse = {
  archived_projects: number
  current_projects: number
  projects: InboundProject[]
}
export interface ProjectsState {
  inboundProjects: InBoundProjectsResponse
  outboundProject: Request[]
}

const initialState: ProjectsState = {
  inboundProjects: {
    archived_projects: 0,
    current_projects: 0,
    projects: [],
  } as InBoundProjectsResponse,
  outboundProject: [] as Request[],
}

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setInboundProjects(state, action: PayloadAction<InBoundProjectsResponse>) {
      // eslint-disable-next-line no-param-reassign
      state.inboundProjects = action.payload
    },
    pushInboundProject(state, action: PayloadAction<InboundProject>) {
      // eslint-disable-next-line no-param-reassign
      state.inboundProjects.projects.push(action.payload)
      // eslint-disable-next-line no-param-reassign
      state.inboundProjects.current_projects =
        state.inboundProjects.projects?.filter(
          ({ status }) => !PROJECT_ARCHIVED_STATUSES.includes(status)
        )?.length
      // eslint-disable-next-line no-param-reassign
      state.inboundProjects.archived_projects =
        state.inboundProjects.projects?.filter(({ status }) =>
          PROJECT_ARCHIVED_STATUSES.includes(status)
        )?.length
    },
    deleteInboundProject(state, action: PayloadAction<{ slug: string }>) {
      // eslint-disable-next-line no-param-reassign
      state.inboundProjects.projects = state.inboundProjects.projects.filter(
        (p) => p.slug !== action.payload.slug
      )
      // eslint-disable-next-line no-param-reassign
      state.inboundProjects.current_projects =
        state.inboundProjects.projects?.filter(
          ({ status }) => !PROJECT_ARCHIVED_STATUSES.includes(status)
        )?.length
      // eslint-disable-next-line no-param-reassign
      state.inboundProjects.archived_projects =
        state.inboundProjects.projects?.filter(({ status }) =>
          PROJECT_ARCHIVED_STATUSES.includes(status)
        )?.length
    },
    updateInboundProject(state, action: PayloadAction<InboundProject>) {
      // eslint-disable-next-line no-param-reassign
      state.inboundProjects.projects = state.inboundProjects.projects.map(
        (p) => {
          if (p.slug === action.payload.slug) {
            return action.payload
          }
          return p
        }
      )
      // eslint-disable-next-line no-param-reassign
      state.inboundProjects.current_projects =
        state.inboundProjects.projects?.filter(
          ({ status }) => !PROJECT_ARCHIVED_STATUSES.includes(status)
        )?.length
      // eslint-disable-next-line no-param-reassign
      state.inboundProjects.archived_projects =
        state.inboundProjects.projects?.filter(({ status }) =>
          PROJECT_ARCHIVED_STATUSES.includes(status)
        )?.length
    },
    updateInboundProjectStatus(
      state,
      action: PayloadAction<{ slug: string; status: PROJECT_STATUS }>
    ) {
      // eslint-disable-next-line no-param-reassign
      state.inboundProjects.projects = state.inboundProjects.projects.map(
        (p) => {
          if (p.slug === action.payload.slug) {
            return { ...p, status: action.payload.status }
          }
          return p
        }
      )
      // eslint-disable-next-line no-param-reassign
      state.inboundProjects.current_projects =
        state.inboundProjects.projects?.filter(
          ({ status }) => !PROJECT_ARCHIVED_STATUSES.includes(status)
        )?.length
      // eslint-disable-next-line no-param-reassign
      state.inboundProjects.archived_projects =
        state.inboundProjects.projects?.filter(({ status }) =>
          PROJECT_ARCHIVED_STATUSES.includes(status)
        )?.length
    },
    setOutboundProjects(state, action: PayloadAction<Request[]>) {
      // eslint-disable-next-line no-param-reassign
      state.outboundProject = action.payload
    },
    pushOutboundProject(state, action: PayloadAction<Request>) {
      // eslint-disable-next-line no-param-reassign
      state.outboundProject.push(action.payload)
    },
  },
})

export const projectActions = projectsSlice.actions

export default projectsSlice
