"use client"

import React, { useRef } from "react"
import { Provider } from "react-redux"
import { makeStore } from "@/store/store"
import { User } from "@/types/User"
import authSlice from "@/store/auth/authSlice"
import { ArtistOrBrand } from "@/types/ArtistOrBrand"

export default function StoreProvider({
  children,
  user,
  masquerade,
}: {
  children: React.ReactNode
  user?: User
  masquerade?: ArtistOrBrand
}) {
  const storeRef = useRef<any>(null)
  if (!storeRef.current) {
    storeRef.current = makeStore()
    storeRef.current.dispatch(authSlice.actions.setUser(user))
    storeRef.current.dispatch(authSlice.actions.setMasquerade(masquerade))
  }

  return <Provider store={storeRef.current}>{children}</Provider>
}
