import { configureStore, ThunkAction } from "@reduxjs/toolkit"
import { Action } from "redux"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import authSlice, { AuthState } from "@/store/auth/authSlice"
import commonSlice, { CommonState } from "@/store/common/commonSlice"
import bookmarksSlice, {
  BookmarksState,
} from "@/store/bookmarks/bookmarksSlice"
import projectsSlice, { ProjectsState } from "@/store/projects/projectsSlice"

export interface RootState {
  auth: AuthState
  common: CommonState
  bookmarks: BookmarksState
  projects: ProjectsState
}

export const makeStore = () =>
  configureStore({
    reducer: {
      [authSlice.name]: authSlice.reducer,
      [commonSlice.name]: commonSlice.reducer,
      [bookmarksSlice.name]: bookmarksSlice.reducer,
      [projectsSlice.name]: projectsSlice.reducer,
    },
    devTools: true,
  })

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<AppStore["getState"]>
export type AppDispatch = AppStore["dispatch"]
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
