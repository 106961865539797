import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { User } from "@/types/User"
import type { ArtistOrBrand } from "@/types/ArtistOrBrand"
import type { TeamMember } from "@/types/TeamMember"

export type Masquerade = Partial<ArtistOrBrand> & {
  active_users?: TeamMember[]
}
export interface AuthState {
  user: User | undefined
  masquerade: Masquerade | undefined
  isLoadingMasqueradeSwitch: boolean
}

const initialState: AuthState = {
  user: undefined,
  masquerade: undefined,
  isLoadingMasqueradeSwitch: false,
}
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.user = action.payload
    },
    setMasquerade(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.masquerade = action.payload
    },
    setMasqueradeSwitchLoading(state, action: PayloadAction<boolean>) {
      // eslint-disable-next-line no-param-reassign
      state.isLoadingMasqueradeSwitch = action.payload
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase("HYDRATE", (state, action) => {
  //     return {
  //       ...state,
  //       // @ts-ignore
  //       ...action?.payload?.user,
  //     }
  //   })
  // },
})

export const authActions = authSlice.actions

export default authSlice
