import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import REDUX_MODAL_KEYS from "@/lib/modals"

export type OpenModalReq = { key: REDUX_MODAL_KEYS; visible: boolean }

export interface CommonState {
  modals: { [key in REDUX_MODAL_KEYS]: boolean }
  isCollapsed: boolean
}

const initialState: CommonState = {
  modals: {} as any,
  isCollapsed: false,
}
const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setModal(state, action: PayloadAction<OpenModalReq>) {
      // eslint-disable-next-line no-param-reassign
      state.modals[action.payload.key] = action.payload.visible
    },
    setIsCollapsed(state, action: PayloadAction<boolean>) {
      // eslint-disable-next-line no-param-reassign
      state.isCollapsed = action.payload
    },
  },
})

export const commonActions = commonSlice.actions

export default commonSlice
